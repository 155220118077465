import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import IconLinkArrowSvg from '../images/icons/link-arrow.svg'
import { isAbsoluteUrl } from '../utils/utils'
import { BREAKPOINTS, ANIMATION } from '../styles/cssVariables'

const IntroText = styled.h2`
  margin-bottom: 50px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }

`

const QuoteText = styled.blockquote`
  padding-left: 28px;
  border-left: 5px solid var(--color-violet);
  font-size: 26px;
  @media (min-width: ${BREAKPOINTS.medUp}px) {
    padding-left: 42px;
    width: 65%;
  }
  @media (max-width: ${BREAKPOINTS.med}px) {
    font-size: 20px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    font-size: 18px;
  }
`

const QuoteContent = styled.div`
  display: flex;
  flex-direction: ${(props) => props.layout};

  @media (max-width: ${BREAKPOINTS.med}px) {
    ${(props) => props.layout === 'row' && 'flex-direction: column;'}
    ${(props) =>
      props.layout === 'row-reverse' && 'flex-direction: column-reverse;'}
  }

  & ${QuoteText} {
    margin: auto;

    ${(props) =>
      (props.layout === 'row' || props.layout === 'row-reverse') &&
      'margin-top: 0;margin-bottom: 0;'}
    // ${(props) => props.layout === 'column' && 'margin-bottom: 72px;'}
    ${(props) => props.layout === 'column-reverse' && 'margin-top: 72px;'}

    @media (max-width: ${BREAKPOINTS.med}px) {
      // ${(props) => props.layout === 'column' && 'margin-bottom: 50px;'}
      ${(props) => props.layout === 'column-reverse' && 'margin-top: 50px;'}
      ${(props) => props.layout === 'row' && 'margin-bottom: 50px;'}
      ${(props) => props.layout === 'row-reverse' && 'margin-top: 50px;'}
    }
  }
`

const ButtonCentered = styled.a`
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  border: 1px solid var(--color-heading);
  background-color: var(--color-heading) !important;
  color: #fff !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff !important;
    color: var(--color-heading) !important;
  }
  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
`

const ButtonCenteredGatsbyLink = styled(Link)`
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  width: fit-content;
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.25em 2.25em;
  border-radius: 4px;
  border: 1px solid var(--color-heading);
  background-color: var(--color-heading) !important;
  color: #fff !important;
  transition: color ${ANIMATION.speedMedium} ease,
  background-color ${ANIMATION.speedMedium} ease;

  &:hover {
    background-color: #fff !important;
    color: var(--color-heading) !important;
  }
  @media (max-width: ${BREAKPOINTS.lrg}px) {
    padding: 1em 1.5em;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    margin-top: 40px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 25px;

  }
`

const Arrow = styled(IconLinkArrowSvg)`
  margin-left: 0.4em;
`


/**
 * Renders a quote with optional CTA.
 * Examples on Join as a Brand, Join as an Expert, etc.
 */
const Quote = (props) => {
  return (
    <>
      {props.introText && <IntroText>{props.introText}</IntroText>}

      <QuoteContent layout={props.layout}>
        <QuoteText>{props.quote}</QuoteText>

        {props.ctaText && (
          <>
            {isAbsoluteUrl(props.ctaDestination) ? (
              <ButtonCentered href={props.ctaDestination} target="__blank" rel="nofollow">
        
                {props.ctaText}
              <Arrow />
          </ButtonCentered>
            ) : (
              <ButtonCenteredGatsbyLink to={props.ctaDestination}>{props.ctaText} <Arrow /></ButtonCenteredGatsbyLink>
            )}
          </>
    
        )}
     
      </QuoteContent>
    </>
  )
}

Quote.propTypes = {
  /** A short introductory paragraph to display above the quote */
  introText: PropTypes.string,
  /** Specifies whether the quote is displayed at the top/bottom of a column, or the start/end of a row */
  layout: PropTypes.oneOf(['row', 'row-reverse', 'column', 'column-reverse'])
    .isRequired,
  /** Quote text */
  quote: PropTypes.string.isRequired,
  ctaText: PropTypes.string,
  ctaDestination: PropTypes.string,
}

export default Quote
