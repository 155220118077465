import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import get from 'lodash/get'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import HeroSecondaryLanding from '../components/ui-kit/heroSecondaryLanding'
import { BREAKPOINTS, LAYOUT } from '../styles/cssVariables'
import Layout from '../components/layout/layout'
import SEO from '../components/layout/seo'
import Container from '../components/layout/container'
import Quote from '../components/quote'
import FeatureList from '../components/featureList'
import LogoGrid from '../components/logoGrid'
import Hero from '../components/hero'




const SectionContainer = styled.section`
`

const QuoteContainer = styled(Container)`
  
  margin-bottom: 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-bottom: 100px;
  }
`



const FeatureListWrapper = styled.section`

    margin-bottom: 200px;
    @media (max-width: ${BREAKPOINTS.med}px) {
      margin-bottom: 175px;
    }
    @media (max-width: ${BREAKPOINTS.tbl}px) {
      margin-bottom: 150px;
    }
    @media (max-width: ${BREAKPOINTS.sml}px) {
      margin-bottom: 100px;
    }

`
const FeatureListTitle = styled.h2`
  margin-bottom: 42px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }

`

const LogoListWrapper = styled.section`
  padding: 0 0 200px;
  @media (max-width: ${BREAKPOINTS.med}px) {
    padding-bottom: 175px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    padding-bottom: 150px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 100px;
  }
`

const LogoListContainer = styled.div`
  padding: 0 ${LAYOUT.gutterPage}px;
  box-sizing: border-box;
  width: 100%;
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 0 ${LAYOUT.gutterPage / 2}px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding: 0 ${LAYOUT.gutterPageMobile}px;
  }
`

const JoinTheCommunityPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query JoinTheCommunityQuery {
      contentfulPageLandingCommunity {
        seoTitle
        seoDescription
        openGraphImage {
          title
          gatsbyImageData(layout: FULL_WIDTH, quality: 85)
        }
        hero {
          heading
          subheading
          ctaText
          ctaDestination
          
        }
        heroSecondaryHeading
        heroSecondaryBody {
          heroSecondaryBody
        }
        quote1 {
          introText {
            introText
          }
          layout
          quote {
            quote
          }
          ctaText
          ctaDestination
        }

        firstFeatureSection {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        secondFeatureSection {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        thirdFeatureSection {
          layout
          image {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
          title
          featureCards {
            heading
            body {
              body
            }
          }
          ctaText
          ctaDestination
        }
        supportersJoinCommunity {
          title
          logos {
            id
            title
            gatsbyImageData(layout: FULL_WIDTH)
            description
          }
          ctaText
          ctaDestination
        }
      }
      
    }
  `)
  const openGraphImage = data.contentfulPageLandingCommunity.openGraphImage

  const heroHeading = data.contentfulPageLandingCommunity.hero.heading
  const heroSubheading = data.contentfulPageLandingCommunity.hero.subheading
  const ctaText = data.contentfulPageLandingCommunity.hero.ctaText
  const ctaDestination = data.contentfulPageLandingCommunity.hero.ctaDestination

  const seoTitle = data?.contentfulPageLandingCommunity?.seoTitle
  const seoDescription = data?.contentfulPageLandingCommunity?.seoDescription
  const heroSecondaryHeading = data.contentfulPageLandingCommunity.heroSecondaryHeading
  const heroSecondaryBody = data.contentfulPageLandingCommunity.heroSecondaryBody.heroSecondaryBody

  const quote1IntroText = data.contentfulPageLandingCommunity.quote1.introText
    ? data.contentfulPageLandingCommunity.quote1.introText.introText
    : undefined
  const quote1Layout = data.contentfulPageLandingCommunity.quote1?.layout
  const quote1Text = data.contentfulPageLandingCommunity.quote1?.quote.quote
  const quote1CtaText = data.contentfulPageLandingCommunity.quote1?.ctaText
  const quote1CtaDestination =
    data.contentfulPageLandingCommunity.quote1?.ctaDestination

  const firstFeatureListLayout =
    data.contentfulPageLandingCommunity.firstFeatureSection.layout
  const firstFeatureListImage =
    data.contentfulPageLandingCommunity.firstFeatureSection.image
  const firstFeatureListTitle =
    data.contentfulPageLandingCommunity.firstFeatureSection.title
  const firstFeatureListCards =
    data.contentfulPageLandingCommunity.firstFeatureSection.featureCards
  const firstFeatureListCtaText =
    data.contentfulPageLandingCommunity.firstFeatureSection.ctaText
  const firstFeatureListCtaDestination =
    data.contentfulPageLandingCommunity.firstFeatureSection.ctaDestination

    const secondFeatureListLayout =
    data.contentfulPageLandingCommunity.secondFeatureSection.layout
  const secondFeatureListImage =
    data.contentfulPageLandingCommunity.secondFeatureSection.image
  const secondFeatureListTitle =
    data.contentfulPageLandingCommunity.secondFeatureSection.title
  const secondFeatureListCards =
    data.contentfulPageLandingCommunity.secondFeatureSection.featureCards
  const secondFeatureListCtaText =
    data.contentfulPageLandingCommunity.secondFeatureSection.ctaText
  const secondFeatureListCtaDestination =
    data.contentfulPageLandingCommunity.secondFeatureSection.ctaDestination

  const thirdFeatureListLayout =
    data.contentfulPageLandingCommunity.thirdFeatureSection.layout
  const thirdFeatureListImage =
    data.contentfulPageLandingCommunity.thirdFeatureSection.image
  const thirdFeatureListTitle =
    data.contentfulPageLandingCommunity.thirdFeatureSection.title
  const thirdFeatureListCards =
    data.contentfulPageLandingCommunity.thirdFeatureSection.featureCards
  const thirdFeatureListCtaText =
    data.contentfulPageLandingCommunity.thirdFeatureSection.ctaText
  const thirdFeatureListCtaDestination =
    data.contentfulPageLandingCommunity.thirdFeatureSection.ctaDestination

    const partners = data.contentfulPageLandingCommunity.supportersJoinCommunity.logos
    const partnersTitle = data.contentfulPageLandingCommunity.supportersJoinCommunity.title

    const partnersCtaText = data.contentfulPageLandingCommunity.supportersJoinCommunity.ctaText
    const partnersCtaDestination = data.contentfulPageLandingCommunity.supportersJoinCommunity.ctaDestination


  return (
    <Layout location={location}>
      <SEO
        pageTitle={seoTitle}
        seoDescription={seoDescription}
        // ogImage={openGraphImage.gatsbyImageData.images.sources[0].srcSet}
      />

      <Hero
      id="join"
        heading={heroHeading}
        subheading={heroSubheading}
        ctaDestination={ctaDestination}
        ctaText={ctaText}
      />

      <HeroSecondaryLanding title={heroSecondaryHeading} body={heroSecondaryBody} />

      <FeatureListWrapper>
        <Container width={12}>
        <FeatureListTitle>
              {firstFeatureListTitle}
          </FeatureListTitle>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={firstFeatureListCtaText}
            ctaDestination={firstFeatureListCtaDestination}
            featureCardArr={firstFeatureListCards.map((card) => [
              card.heading,
              card.body ? card.body.body : undefined,
            ])}

            image={firstFeatureListImage}
            layout={firstFeatureListLayout}
          />
        </Container>
      </FeatureListWrapper>

      <FeatureListWrapper>
        <Container width={12}>
        <FeatureListTitle>
              {secondFeatureListTitle}
          </FeatureListTitle>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={secondFeatureListCtaText}
            ctaDestination={secondFeatureListCtaDestination}
            featureCardArr={secondFeatureListCards.map((card) => [
              card.heading,
              card.body ? card.body.body : undefined,
            ])}

            image={secondFeatureListImage}
            layout={secondFeatureListLayout}
          />
        </Container>
      </FeatureListWrapper>
      
      <SectionContainer>
        <QuoteContainer width={12}>
          <Quote
            ctaDestination={quote1CtaDestination}
            ctaText={quote1CtaText}
            introText={quote1IntroText}
            layout={quote1Layout}
            quote={quote1Text}
          />
        </QuoteContainer>
      </SectionContainer>

      <FeatureListWrapper>
        <Container width={12}>
        <FeatureListTitle>
              {thirdFeatureListTitle}
          </FeatureListTitle>
          <FeatureList
            accentColor="var(--color-violet)"
            ctaText={thirdFeatureListCtaText}
            ctaDestination={thirdFeatureListCtaDestination}
            featureCardArr={thirdFeatureListCards.map((card) => [
              card.heading,
              card.body ? card.body.body : undefined,
            ])}

            image={thirdFeatureListImage}
            layout={thirdFeatureListLayout}
          />
        </Container>
      </FeatureListWrapper>

        <LogoListWrapper>
          <LogoListContainer width={12}>
            <LogoGrid
              ctaDestination={partnersCtaDestination}
              ctaText={partnersCtaText}
              logos={partners}
              title={partnersTitle}
            />
          </LogoListContainer>
        </LogoListWrapper>
      
    </Layout>
  )
}

export default JoinTheCommunityPage
